import { IClientApprovalWithToken } from 'models/clientApproval.model';
import { ECustomHeaders } from 'shared/enums/customHeaders.enum';
import { EGrecaptchaEnabledFlags } from 'shared/enums/grecaptchaEnabledFlags.enum';
import { AuthHelper } from 'utils/authHelper.util';
import { IUserInfoResponse } from 'models/auth/userInfo.model';
import { ICheckLinkResponse } from 'models/redirect/redirect.model';
import { ICheckEmailResponse } from 'shared/models/account/accountCheckEmail.model';

export class AccountApiService {
  static async submitUserApproval(user: IClientApprovalWithToken & { supplier: boolean }): Promise<string | { statusCode?: number }> {
    const headers =
      useRuntimeConfig().public.grecaptchaEnabled === EGrecaptchaEnabledFlags.Enabled
        ? {
          [ECustomHeaders.RecaptchaV2Token]: user.token,
        }
        : {};

    return await AuthHelper.fetch<string>('/api/v1/clients_approval', {
      method: 'POST',
      body: {
        clientName: user.clientName,
        companyName: user.companyName,
        email: user.email,
        phone: user.phone,
        supplier: user.supplier,
      },
      headers,
    });
  }

  static async verifyEmail(tokenUuid: string): Promise<void> {
    return await AuthHelper.fetch<void>(`/api/v1/accounts/verify_email/${tokenUuid}`, {
      method: 'POST',
    });
  }

  public static async changeMobilePhone(phone: string): Promise<void> {
    return await AuthHelper.fetch('/api/v1/accounts/phone/change', {
      method: 'POST',
      body: { phone },
    });
  }

  public static async confirmCode(code: string): Promise<IUserInfoResponse> {
    return await AuthHelper.fetch('/api/v1/accounts/phone/confirm', {
      method: 'POST',
      body: { code },
    });
  }

  public static async requestEmailChange(email: string, supplierId?: number): Promise<void> {
    return await AuthHelper.fetch('/api/v1/accounts/request_email_change', {
      method: 'POST',
      body: { email, supplierId },
    });
  }

  public static async confirmEmailChange(tokenUuid: string): Promise<void> {
    return await AuthHelper.fetch(`/api/v1/accounts/confirm_email_change/${tokenUuid}`, {
      method: 'POST',
    });
  }

  public static async confirmPasswordChange(password: string, tokenUuid: string): Promise<void> {
    return await AuthHelper.fetch(`/api/v1/accounts/confirm_password_change/${tokenUuid}`, {
      method: 'POST',
      body: {
        password,
      },
    });
  }

  public static async confirmPasswordCreate(password: string, tokenUuid: string): Promise<void> {
    return await AuthHelper.fetch(`/api/v1/accounts/confirm_password_create/${tokenUuid}`, {
      method: 'POST',
      body: {
        password,
      },
    });
  }

  public static async validateToken(tokenUuid: string): Promise<boolean> {
    return await AuthHelper.fetch(`/api/v1/accounts/validate_token/${tokenUuid}`, {
      method: 'POST',
    });
  }

  public static async requestPasswordChange(email: string, userType: string): Promise<void> {
    return await AuthHelper.fetch('/api/v1/accounts/request_password_change', {
      method: 'POST',
      params: {
        userType,
      },
      body: {
        email,
      },
    });
  }

  public static async requestPasswordRestore(email: string): Promise<void> {
    return await AuthHelper.fetch('/api/v1/accounts/request_password_restore', {
      method: 'POST',
      body: {
        email,
      },
    });
  }

  public static async checkLinkToken(tokenUuid: string): Promise<ICheckLinkResponse> {
    return await AuthHelper.fetch(`/api/v1/accounts/check/${tokenUuid}`);
  }

  public static async checkUserExistenceByEmail(email: string): Promise<ICheckEmailResponse> {
    return await AuthHelper.fetch('/api/v1/accounts/user_existence', {
      method: 'POST',
      body: {
        email,
      },
    });
  }

  public static async changeAdditionalMobilePhone(additionalCode: string | null): Promise<void> {
    return await AuthHelper.fetch('/api/v1/users/additional_code', {
      method: 'PUT',
      body: { additionalCode },
    });
  }
  
  public static async changeBEAdditionalMobilePhone(additionalCode: string | null, beId: number): Promise<void> {
    return await AuthHelper.fetch(`/api/v1/business_entities/update_user_contact_info/${beId}`, {
      method: 'POST',
      body: { additionalCode },
    });
  }
  
  public static async changeSupplierPhone(phone: string): Promise<void> {
    return await AuthHelper.fetch('/api/v1/users/change_phone_by_sub/', { method: 'POST', body: { phone }});
  }
}
